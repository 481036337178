import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { ConfirmationService, SelectItem } from 'primeng/api'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { DocumentService } from '../../../services/document.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { PatientModel } from '../../../models/customer-patient/patient.model'
import { HelperService } from '../../../services/helper.service'
import { PatientService } from '../../../services/patient.service'

@Component({
  selector: 'app-add-document-dialog',
  templateUrl: './add-document-dialog.component.html',
})
export class AddDocumentDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  private selectedFile: any = null

  public canUpload = true
  public canNotUploadMessage = ''
  public canBeApproved = false

  public data: any = {}
  public values = {
    upload_type: 'upload',
    text_end_dlv_anschreiben: '',

    // Einige Dokumente müssen 2x generiert werden.
    // Im Backend wird anhand is_email geprüft ob das Dokument 2x oder nur 1x erstellt werden soll.
    // Beim generieren wollen wir das Dokument immer 2x erstellen.
    is_email: false,
    as_pdf: false,

    document_type: '',
    month: '',
    year: '',
    name: '',
    valid_until: '',
    valid_unlimited: true,
    approved_for_user: false,
    selected_file: '',
    receiver: '',
    budget_types: [''],
    price_change2022_type: '',
    price_change2022_caregiver_id: '',
    price_change2024_type: '',
    price_change2024_caregiver_id: '',
  }
  public submitted = false
  public submittedDelete = false

  public isDeletable = true
  public isEdit = false
  public options: SelectItem[] = []
  public caregiverOptions: SelectItem[] = []
  public receiverOptions: any[] = []
  public patientOptions: any[] = []

  public approvalDocumentTypes = {
    caregivers: [
      'misc',
      'urlaub',
      'au',
      'tankkarten-arbeitsanweisungen',
      'fahrtenbuch',
      'dienstfahrzeugüberlassungsvertrag',
      'fahrzeug-rückgabe',
      'hygienefolgebelehrung',
      'materialliste',
      'fahrzeug-übergabe',
      'internetpauschale',
    ],
    patient: [],
  }

  public budgetTypes: string[] = []
  public monthOptions: any = []
  public yearOptions = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
  ]

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private documentService: DocumentService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private patientService: PatientService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data
    this.isEdit = this.data.document

    const date = new Date()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.values.month = this.monthOptions[date.getMonth()].label
      this.values.year = date.getFullYear().toString()

      this.caregiverOptions = data.caregivers_active

      if (this.data.type === 'patients') {
        this.loadBudgetTypes()
      }
    })

    if (this.isEdit) {
      this.values.document_type = this.data.document.collection_name
      this.values.name = this.data.document.name
      this.values.valid_until = this.data.document.custom_properties.valid_until
      this.values.valid_unlimited =
        this.data.document.custom_properties.valid_unlimited === 'true' ||
        this.data.document.custom_properties.valid_unlimited === true

      if (this.data.document.approved_for_user) {
        this.values.approved_for_user = true
        this.canBeApproved = true
      }

      // Bei der Preisänderung 2024 müssen weitere Daten nachgeladen werden.
      if (this.data.document.collection_name === 'preisänderung-2024') {
        this.documentService
          .getPriceChange2024MediaData(this.data.document.id)
          .subscribe((response: any) => {
            this.values.price_change2024_type = response.type
            this.values.price_change2024_caregiver_id = response.caregiver_id
          })
      }

      this.setIsDeletable()
    }

    this.checkForCanBeApproved()

    if (!this.isEdit) {
      this.buildOptions()
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  // Nicht jedes Dokument hat eine Gültigkeit.
  public showValidField(): boolean {
    return [
      'hygienefolgebelehrung',
      'bestätigung-führungszeugnis',
      'nachweis-beratung-37',
    ].includes(this.values.document_type)
  }

  // Manche Dokumente sollen im Frontend nicht mehr löschbar sein.
  private setIsDeletable(): void {
    if (this.data.document_type === 'Fahrtzeit Dienstplan') {
      this.isDeletable = false
    }
  }

  /**
   * Wir prüfen, ob das Dokument überhaupt für den Benutzer freigegeben werden kann.
   * Nicht jeder Typ darf für den Benutzer freigegeben werden.
   */
  private checkForCanBeApproved(): void {
    // Müssen den check nur durchführen, falls der Wert nicht bereits im edit check durchgeführt wurde.
    if (this.isEdit && !this.canBeApproved) {
      if (
        this.data.type === 'caregivers' &&
        this.approvalDocumentTypes.caregivers.includes(
          this.data.document.collection_name
        )
      ) {
        this.canBeApproved = true
      }
    } else if (!this.isEdit) {
      this.values.approved_for_user = false
      this.canBeApproved = false

      // Ansonsten muss geprüft werden, ob der ausgewählte Typ freigegeben werden kann.
      if (
        this.data.type === 'caregivers' &&
        this.approvalDocumentTypes.caregivers.includes(
          this.values.document_type
        )
      ) {
        this.canBeApproved = true
      }
    }
  }

  // Wenn Empfänger ausgewählt wird, muss der Endtext noch angepasst werden.
  public receiverChanged(event: any): void {
    if (this.values.receiver.includes('ap')) {
      const patient = this.patientOptions.find(
        (patient: PatientModel) => patient.id === this.data.type_id
      )

      this.values.text_end_dlv_anschreiben = `Um die oben genannten Unterlagen nochmal zu besprechen, aber auch Sie und den Haushalt von ${patient.full_name} kennenzulernen, werden wir Sie zeitnah telefonisch kontaktieren und einen persönlichen Besuch vereinbaren. Für diesen umfangreichen Beratungs- und Einrichtungstermin berechnen wir pauschal 2 Stunden.`
    } else {
      this.values.text_end_dlv_anschreiben =
        'Um die oben genannten Unterlagen nochmal zu besprechen, aber auch Sie und Ihren Haushalt kennenzulernen, werden wir Sie zeitnah telefonisch kontaktieren und einen persönlichen Besuch vereinbaren. Für diesen umfangreichen Beratungs- und Einrichtungstermin berechnen wir pauschal 2 Stunden.'
    }
  }

  public buildOptions(): void {
    this.values.document_type = ''
    this.canNotUploadMessage = ''
    this.canUpload = true
    this.options = []
    this.patientOptions = []
    this.receiverOptions = []

    if (this.data.default_document_type) {
      this.values.document_type = this.data.default_document_type
    }

    if (this.values.upload_type === 'upload') {
      this.options.push({
        label: 'Sonstiges',
        value: 'misc',
      })
    }

    if (this.data.type === 'caregivers') {
      this.buildCaregiverOptions()
    }

    if (this.data.type === 'patients') {
      this.buildPatientOptions()
      this.buildReceiverOptions()
    }

    // Bei Schäden soll das "Typ" Selectfeld ausgeblendet werden
    // und automatisch den typ "schaden" nehmen.
    if (this.data.type === 'car_damage') {
      this.values.document_type = 'schaden'
    }
  }

  private buildReceiverOptions(): void {
    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${this.data.patients.first_patient.id}`,
      label: this.data.patients.first_patient.full_name + ' - Patient',
    })

    if (this.data.patients.second_patient) {
      this.receiverOptions.push({
        value: `patient_${this.data.patients.second_patient.id}`,
        label: this.data.patients.second_patient.full_name + ' - Patient',
      })
    }

    for (const contactPerson of this.data.contact_persons) {
      this.receiverOptions.push({
        value: `ap_${contactPerson.id}`,
        label: contactPerson.full_name + ' - Ansprechpartner',
      })
    }
  }

  private buildPatientOptions(): void {
    this.patientOptions.push({
      id: this.data.patients.first_patient.id,
      full_name: this.data.patients.first_patient.full_name,
    })

    if (this.data.patients.second_patient) {
      this.patientOptions.push({
        id: this.data.patients.second_patient.id,
        full_name: this.data.patients.second_patient.full_name,
      })
    }

    if (this.values.upload_type === 'upload') {
      this.options.push({
        label: 'Preisänderung 2024',
        value: 'preisänderung-2024',
      })
    }

    this.options.push({
      label: 'Willkommensschreiben',
      value: 'willkommensschreiben',
    })

    if (this.values.upload_type === 'generate') {
      this.options.push({
        label: 'Preisänderung 2024',
        value: 'preisänderung-2024-blanko',
      })
    }

    this.options.push({
      label: 'Nachweis Beratungseinsatz §37',
      value: 'nachweis-beratung-37',
    })

    this.options.push({
      label: 'SEPA',
      value: 'sepa',
    })

    this.options.push({
      label: 'DLV Anschreiben',
      value: 'dlv-anschreiben',
    })

    this.options.push({
      label: 'DLV',
      value: 'dlv',
    })

    this.options.push({
      label: 'Angebot',
      value: 'offer',
    })

    // Leistungsnachweis kann nicht mehr separat beim Patienten hochgeladen werden.
    if (this.values.upload_type === 'generate') {
      this.options.push({
        label: 'Leistungsnachweis',
        value: 'leistungsnachweis',
      })
    }

    this.options.push({
      label: 'Kundeninformation',
      value: 'kundeninformation',
    })

    this.options.push({
      label: 'Kundenaufnahmebogen',
      value: 'kundenaufnahmebogen',
    })

    this.options.push({
      label: 'Abtretung',
      value: 'abtretung',
    })

    this.options.push({
      label: 'Entbindung',
      value: 'entbindung',
    })

    if (this.values.upload_type === 'upload') {
      this.options.push({
        label: 'Rechnung',
        value: 'rechnung',
      })

      this.options.push({
        label: 'Mahnung',
        value: 'mahnung',
      })

      this.options.push({
        label: 'Rückläufer',
        value: 'rückläufer',
      })

      this.options.push({
        label: 'OptaData',
        value: 'optadata',
      })

      this.options.push({
        label: 'Vollmacht',
        value: 'vollmacht',
      })

      this.options.push({
        label: 'Einzelfallentscheidung',
        value: 'einzelfallentscheidung',
      })

      this.options.push({
        label: 'Kostenzusage Kostenträger',
        value: 'kostenzusage-kostenträger',
      })

      this.options.push({
        label: 'Kostenzusage UV',
        value: 'kostenzusage-uv',
      })
    }
  }

  private buildCaregiverOptions(): void {
    this.options.push({
      label: 'Tankkarten Arbeitsanweisungen',
      value: 'tankkarten-arbeitsanweisungen',
    })
    this.options.push({ label: 'Fahrtenbuch', value: 'fahrtenbuch' })
    this.options.push({
      label: 'Personalfragebogen',
      value: 'personalfragebogen',
    })
    this.options.push({
      label: 'Dienstfahrzeugüberlassungsvertrag',
      value: 'dienstfahrzeugüberlassungsvertrag',
    })
    this.options.push({
      label: 'Bestätigung Führungszeugnis',
      value: 'bestätigung-führungszeugnis',
    })
    this.options.push({
      label: 'Fahrzeug Rückgabe',
      value: 'fahrzeug-rückgabe',
    })
    this.options.push({
      label: 'Fahrzeug Übergabe',
      value: 'fahrzeug-übergabe',
    })
    this.options.push({
      label: 'Internetpauschale',
      value: 'internetpauschale',
    })
    this.options.push({
      label: 'Arbeitsvertrag',
      value: 'arbeitsvertrag',
    })
    this.options.push({
      label: 'Kostenerstattung',
      value: 'kostenerstattung',
    })
    this.options.push({
      label: 'Hygienefolgebelehrung',
      value: 'hygienefolgebelehrung',
    })
    this.options.push({
      label: 'Materialliste zu Arbeitsbeginn',
      value: 'materialliste',
    })
    this.options.push({
      label: 'Bewerbungsunterlagen',
      value: 'application-document',
    })
  }

  public documentTypeSelected(event: any): void {
    this.canUpload = true
    this.canNotUploadMessage = ''
    this.values.name = ''

    this.checkForCanBeApproved()

    if (this.values.document_type === 'preisänderung-2024') {
      this.values.name = 'Preisänderung 2024 Unterschrieben'
    }

    if (this.values.document_type === 'preisänderung-2024-blanko') {
      this.values.name = 'Preisänderung 2024 Blanko'
    }

    if (this.values.document_type === 'internetpauschale') {
      if (this.values.upload_type === 'generate') {
        this.values.name = 'Internetpauschale Blanko'
      } else {
        this.values.name = 'Internetpauschale mit Unterschrift'
      }
    }

    // Die Hygienefolgebelehrung soll nur möglich sein, wenn
    // das Datum bei der BK hinterlegt ist.
    if (this.values.document_type === 'hygienefolgebelehrung') {
      this.canUpload = false

      this.documentService
        .canUploadCheck('hygienefolgebelehrung', this.data.type_id)
        .subscribe(({ can_upload, can_not_upload_message }) => {
          this.canUpload = can_upload
          this.canNotUploadMessage = can_not_upload_message
        })
    }
  }

  public setSelectedFile(event: any): void {
    this.selectedFile = event.target.files[0]
  }

  public loadBudgetTypes(): void {
    this.patientService
      .getBudgetTypesForMonth(
        this.data.type_id,
        this.values.year,
        this.values.month
      )
      .subscribe((types: string[]) => {
        this.budgetTypes = types
        this.values.budget_types = types
      })
  }

  /**
   * Lädt eine Datei hoch.
   */
  public upload(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const formData = new FormData()
    formData.append('file', this.selectedFile)
    formData.append('type', this.data.type)
    formData.append('type_id', this.data.type_id)
    formData.append('document_type', this.values.document_type)
    formData.append('name', this.values.name)
    formData.append('valid_until', this.values.valid_until)
    formData.append('receiver', this.values.receiver)
    formData.append(
      'price_change2024_caregiver_id',
      this.values.price_change2024_caregiver_id
    )
    formData.append('price_change2024_type', this.values.price_change2024_type)
    formData.append(
      'approved_for_user',
      this.values.approved_for_user.toString()
    )
    formData.append('valid_unlimited', this.values.valid_unlimited.toString())

    const subscription = this.documentService.upload(formData)

    subscription.subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.DocumentListReload)
        this.ref.close(response)
        this.toastService.success(
          'Dokument hochgeladen',
          'Das Dokument wurde erfolgreich hochgeladen'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  /**
   * Generiert eine Datei.
   */
  public generate(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.documentService.generate(
      this.data.type_id,
      this.data.type,
      this.values
    )

    subscription.subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.DocumentListReload)
        this.ref.close(response)
        this.toastService.success(
          'Dokument generiert',
          'Das Dokument wurde erfolgreich in generiert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  /**
   * Bearbeitet eine Datei.
   */
  public edit(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.documentService.edit(this.data.document.id, this.values).subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.DocumentListReload)
        this.ref.close()
        this.toastService.success(
          'Dokument bearbeitet',
          'Das Dokument wurde erfolgreich bearbeitet'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dokument wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.documentService.remove(this.data.document.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.DocumentListReload)
            this.ref.close()
            this.toastService.success(
              'Dokument gelöscht',
              'Das Dokument wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Das Dokument konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  public archive(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dokument wirklich archivieren?',
      accept: () => {
        this.submittedDelete = true

        this.documentService.archive(this.data.document.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.DocumentListReload)
            this.ref.close()
            this.toastService.success(
              'Dokument archiviert',
              'Das Dokument wurde erfolgreich archiviert'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Archivierung fehlgeschlagen',
              'Das Dokument konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  public unarchive(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Dokument wiederherstellen?',
      accept: () => {
        this.submittedDelete = true

        this.documentService.unarchive(this.data.document.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.DocumentListReload)
            this.ref.close()
            this.toastService.success(
              'Dokument wiederhergestellt',
              'Das Dokument wurde erfolgreich wiederhergestellt'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Wiederherstellung fehlgeschlagen',
              'Das Dokument konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
