import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AccountingReturnModel } from '../models/accounting/accounting-return.model'
import { AccountingReturnHistoryModel } from '../models/accounting/accounting-return-history.model'

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(private http: HttpClient) {}

  public approvals(month: string, year: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/plans/approvals`, {
      params: {
        month,
        year,
      },
    })
  }

  public search(
    filters: any,
    query: string
  ): Observable<AccountingReturnModel[]> {
    return this.http.get<AccountingReturnModel[]>(
      `${environment.api}/plans/search?query=${query}&filters=${filters}`
    )
  }

  public loadCreated(filters: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/plans/created`, {
      params: {
        ...filters,
      },
    })
  }

  public loadEdited(filters: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/plans/edited`, {
      params: {
        ...filters,
      },
    })
  }

  public loadFinished(filters: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/plans/finished`, {
      params: {
        ...filters,
      },
    })
  }

  public changeStatus(
    type: string,
    typeId: number,
    status: string,
    caregiver_appointment_change: any | null = null,
    data = null
  ): Observable<[]> {
    return this.http.put<[]>(`${environment.api}/plans/status`, {
      status,
      typeId,
      type,
      caregiver_appointment_change,
      data,
    })
  }

  public saveWithHistory(
    type: string,
    typeId: number,
    values: any,
    caregiver_appointment_change: any | null = null,
    data = null
  ): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/plans/save-with-history`, {
      comment: values.comment,
      important: values.important,
      typeId,
      type,
      caregiver_appointment_change,
      data,
    })
  }

  public loadComments(type: string, typeId: number): Observable<[]> {
    return this.http.get<[]>(`${environment.api}/plans/comments`, {
      params: {
        type,
        typeId,
      },
    })
  }

  public storeComment(
    type: string,
    typeId: number,
    comment: string
  ): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/plans/comment`, {
      comment,
      type,
      typeId,
    })
  }

  public updateComment(
    adminCommentId: number | string,
    comment: string
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/plans/comment/${adminCommentId}`,
      {
        comment,
      }
    )
  }
}
