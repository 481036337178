<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <div class="content-header-patients-tabs">
      <a
        href="/patients/{{ patients.first_patient.id }}/consulting"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.first_patient.appointment_lock"
        ></i>
        <i class="pi pi-user"></i>
        {{ patients.first_patient.full_name }}

        <i
          *ngIf="patients.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.first_patient.status }}"
          >{{ patients.first_patient.status_string }}</i
        >

        <i
          *ngIf="patients.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
      <a
        href="/patients/{{ patients.second_patient.id }}/consulting"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="patients.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ patients.second_patient.full_name }}

        <i
          *ngIf="patients.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.second_patient.status }}"
          >{{ patients.second_patient.status_string }}</i
        >

        <i
          *ngIf="patients.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
    </div>
    <div class="content-header-buttons">
      <a
        [href]="
          patientService.getConsultingPdfPreviewLink(
            patients[selectedPatient].id
          )
        "
        target="_blank"
      >
        <button
          pButton
          label="PDF öffnen"
          type="button"
          class="p-button-outlined"
          icon="pi pi-file"
          iconPos="left"
        ></button>
      </a>

      <button
        *ngIf="authService.can('Kunde.Beratungseinsätze erstellen')"
        pButton
        label="Neuer Beratungseinsatz"
        class="p-button p-button-info"
        type="button"
        (click)="openConsultingDialog()"
        icon="pi pi-plus"
        iconPos="left"
      ></button>
    </div>
  </header>
</div>

<div class="content" *ngIf="!loading">
  <div class="content-body auto-height">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-2" style="margin: 0">
        <label> Name</label>
        <strong>{{ patients[selectedPatient].last_name }}</strong>
      </div>
      <div class="p-field p-col-2" style="margin: 0">
        <label> Vorname</label>
        <strong>{{ patients[selectedPatient].first_name }}</strong>
      </div>
      <div class="p-field p-col-3" style="margin: 0">
        <label> PLZ / Ort</label>
        <strong
          >{{ patients[selectedPatient].zipcode }}
          {{ patients[selectedPatient].city }}
          {{ patients[selectedPatient].city_part }}</strong
        >
      </div>
      <div class="p-field p-col-2" style="margin: 0">
        <label> Pflegegrad</label>
        <strong
          >{{ patients[selectedPatient].degree_of_care || "-" }}
          {{
            patients[selectedPatient].degree_of_care_since_string
              ? "(" +
                patients[selectedPatient].degree_of_care_since_string +
                ")"
              : ""
          }}</strong
        >
      </div>
      <div class="p-field p-col-2" style="margin: 0">
        <label> Abrechnungsstatus</label>
        <strong class="color-alert"
          >{{ patients[selectedPatient].insured_via_string || "-" }}
          {{
            patients[selectedPatient].insured_via_other_text
              ? "(" + patients[selectedPatient].insured_via_other_text + ")"
              : ""
          }}</strong
        >
        <strong
          class="color-gray"
          *ngIf="
            patients[selectedPatient].insured_via.includes('Selbstzahler') &&
            patients[selectedPatient].selbstzahler_budget_type
          "
        >
          Selbstzahler
          {{ patients[selectedPatient].selbstzahler_budget_type }}
          {{
            patients[selectedPatient].selbstzahler_budget_type === "Max"
              ? patients[selectedPatient].selbstzahler_budget_max + " Std"
              : ""
          }}</strong
        >
      </div>
    </div>
  </div>
</div>

<div class="content" *ngIf="!loading">
  <div
    class="content-body"
    *ngIf="patients[selectedPatient].consultings.length == 0"
  >
    <span class="content-no-message">Keine Beratungseinsätze vorhanden</span>
  </div>

  <div
    class="content-body without-padding auto-height"
    *ngIf="patients[selectedPatient].consultings.length > 0"
  >
    <p-table
      #dt
      [value]="patients[selectedPatient].consultings"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th>Status</th>
          <th>Datum</th>
          <th>Für Mitarbeiter</th>
          <th>Erstellt am</th>
          <th>Übergabe per</th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>
            <span class="p-tag p-tag-rounded p-tag-{{ item.status }}">{{
              item.status_value
            }}</span>

            <span
              (click)="openMediaFromUuid(item.media[0].uuid)"
              style="margin: 0 0 0 5px"
              *ngIf="item.media[0]"
              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
              ><i class="pi pi-file-pdf"></i>Nachweis</span
            >

            <span
              (click)="openInvoiceDialog(item.invoice)"
              style="margin: 0 0 0 5px"
              *ngIf="item.invoice && !item.invoice.is_storno"
              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
              ><i class="pi pi-euro"></i>Rechnung:
              {{ item.invoice.status_text }}</span
            >

            <span
              (click)="openInvoiceDialog(item.invoice)"
              style="margin: 0 0 0 5px"
              [pTooltip]="item.invoice.storno_history?.manual_description"
              *ngIf="item.invoice && item.invoice.is_storno"
              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"
              ><i class="pi pi-euro"></i>Rechnung: Storno</span
            >
          </td>
          <td>
            {{ item.date_string }}
          </td>
          <td>
            {{ item.for_user }}
          </td>
          <td>{{ item.created_history.occurred_at_string }}</td>
          <td>{{ item.sent_type_string }}</td>
          <td>
            <i
              tooltipPosition="left"
              *ngIf="item.comment"
              style="font-size: 14px"
              class="pi pi-info-circle color-gray"
              [pTooltip]="item.comment"
            ></i>
          </td>
          <td class="table-action" style="width: 100px">
            <i
              *ngIf="
                item.status !== 'DELETED' &&
                authService.can('Kunde.Beratungseinsätze erstellen')
              "
              pTooltip="Bearbeiten"
              (click)="openConsultingEditDialog(item)"
              tooltipPosition="left"
              class="pi pi-pencil with-margin-right"
            ></i>

            <i
              *ngIf="
                item.status !== 'DELETED' &&
                authService.can('Kunde.Beratungseinsätze erstellen')
              "
              (click)="openConsultingConfirmDialog(item)"
              pTooltip="Bestätigen"
              tooltipPosition="left"
              class="pi pi-check"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
